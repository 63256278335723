import React, { FC, useEffect, useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import { ModalBody } from '../../../components/bootstrap/Modal';
import Textarea from '../../../components/bootstrap/forms/Textarea';
// import { editPatientDataSource, getPatients } from '../../../../api/api';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import * as qs from 'qs';
import { IPatientCreate } from '@textnpayme/custom/api/interface/patient.interface';
import { PatientService } from '../../api/service/patient.service';

interface INewPatientSecondStepProps {
	setValues(...args: unknown[]): unknown;
	setPatientNext(...args: unknown[]): unknown;
	setIsOpen(...args: unknown[]): unknown;
	changeValues: any,
	values: any;
}

const NewPatientSecondStep: FC<INewPatientSecondStepProps> = ({
	setValues,
	setPatientNext,
	changeValues,
	setIsOpen,
	values
}) => {
	const [checkboxValue, setCheckboxValue] = useState<string>('');
	const [disabled, setDisabled] = useState(true);
	const [textareaValue, setTextareaValue] = useState<string>('');

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, checked } = event.target;
		const labelValue = document.querySelector(`label[for=${id}]`)?.textContent || '';
		changeValues('know',  labelValue)
		if (checked) {
			if (id === 'check6') {
				setDisabled(false);
			} else {
				setDisabled(true);
			}
			

			setCheckboxValue(labelValue);
			setTextareaValue('');
		} else {
			setCheckboxValue('');
		}
	};

	const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		changeValues('know',  checkboxValue.includes('Свой вариант') ? event.target.value : checkboxValue)
		setTextareaValue(event.target.value);
	};
	const queryClient = useQueryClient();

	const { mutate } = useMutation((inputDto: IPatientCreate) => PatientService.create(values), {
		onSuccess: (response, formData) => {
			queryClient.invalidateQueries('patients');
			queryClient.invalidateQueries('patient');
			setValues({
				phone: '+374',
				name: '',
				surname: '',
				middleName: '',
				know: ''
			});
			setCheckboxValue('');
			setTextareaValue('');
			setDisabled(true);
			setIsOpen(false)
			setPatientNext(true)
		},
		onError: (error) => {
			console.log(error);
		},
	});
	const handleSave = async () => {		
		await mutate(values);
	};
	

	

	return (
		<ModalBody className='d-flex flex-column align-items-center p-4 gap-5'>
			<div className='d-flex col-11 flex-row justify-content-start gap-3'>
				<div className='d-flex flex-column col-6 gap-4'>
					<input
						type='checkbox'
						id='check'
						checked={checkboxValue.includes('Фейсбук')}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor='check' className='checkBoxContainer'>
						<span className='custom-checkbox'>
							{' '}
							<span></span>{' '}
						</span>
						Фейсбук
					</label>
					<input
						type='checkbox'
						id='check1'
						checked={checkboxValue.includes('Реклама (оффлайн)')}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor='check1' className='checkBoxContainer'>
						<span className='custom-checkbox'>
							{' '}
							<span></span>{' '}
						</span>
						Реклама (оффлайн)
					</label>
				</div>
				<div className='d-flex flex-column col-6 gap-4'>
					{' '}
					<input
						type='checkbox'
						id='check2'
						checked={checkboxValue.includes('Знакомый/друг/семья')}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor='check2' className='checkBoxContainer'>
						<span className='custom-checkbox'>
							{' '}
							<span></span>{' '}
						</span>
						Знакомый/друг/семья
					</label>
					<input
						type='checkbox'
						id='check3'
						checked={checkboxValue.includes('Действующий пациент')}
						onChange={handleCheckboxChange}
					/>
					<label htmlFor='check3' className='checkBoxContainer'>
						<span className='custom-checkbox'>
							{' '}
							<span></span>{' '}
						</span>
						Действующий пациент
					</label>
				</div>
			</div>

			<div className='col-12'>
				<input
					type='checkbox'
					id='check6'
					checked={checkboxValue.includes('Свой вариант')}
					onChange={handleCheckboxChange}
				/>
				<label htmlFor='check6' className='checkBoxContainer'>
					<span className='custom-checkbox'>
						{' '}
						<span></span>{' '}
					</span>
					Свой вариант
				</label>
			</div>
			<Textarea value={textareaValue} disabled={disabled} onChange={handleTextareaChange} />
			<div className='d-flex justify-content-between w-100'>
				<Button
					className='col-5'
					onClick={() =>{
						setPatientNext(true)
					}}
					color='info'
					isLight>
					Назад
				</Button>
				<Button
					className='col-5'
					color='primary'
					onClick={() => {
						handleSave();
					}}>
					Сохранить
				</Button>
			</div>
		</ModalBody>
	);
};

export default NewPatientSecondStep;
