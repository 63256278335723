import { authHost, host } from './axios';

async function login(email: string, password: string, clinicUuid: string) {
	const res = await host.post(`auth/login/admin`, {
		email,
		password,
		clinicUuid,
	});
	const { access_token, refresh_token } = res.data;
	localStorage.setItem('access_token', access_token);
	localStorage.setItem("refresh_token", refresh_token);

	return res.data;
}

export default login;

export interface IClinicProps {
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date;
	uuid: string;
	name: string;
	address: string;
}
export interface IVisitProps {
	createdAt: Date;
	updatedAt: Date;
	deletedAt: Date;
	uuid: string;
}
// export interface IPatientProps {
// 	createdAt: string;
// 	updatedAt: string;
// 	deletedAt: string;
// 	uuid: string;
// 	middleName: string;
// 	email: string;
// 	name: string;
// 	surname: string;
// 	age: number;
// 	gender: string;
// 	debt: string;
// 	visitCount: string;
// 	status: string;
// 	phone: string;
// }

const getClinicByBranchname = async (branchName: string): Promise<{ data: IClinicProps[] }> => {
	const data = await host.get(`clinic?branchName=${branchName}`);
	return data;
};

const getVisits = async (): Promise<{ data: IVisitProps[] }> => {
	const data = await authHost.get(`visit`);
	return data;
};
const getPatients = async (query?: any) => {
	const data = await authHost.get(`patient?${query}`);
	return data.data.data;
};


const getAllClinics = async (): Promise<{ data: IClinicProps[] }> => {
	const { data } = await host.get(`clinic`);
	return data;
};



const editPatientDataSource = async (uuid: string, know: string) => {
	const data = await authHost.patch(`patient/${uuid}`, { know });
	return data;
};

export {
	getClinicByBranchname,
	getVisits,
	getPatients,
	getAllClinics,
	// getPatient,
	editPatientDataSource,
};
