/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { useMutation, useQueryClient } from 'react-query';
import useDarkMode from '../../../hooks/useDarkMode';
import { StageService } from '../../../custom/api/service/stage.service';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { IStage } from '@textnpayme/custom/api/interface/stage.interface';
import { IAct } from '@textnpayme/custom/api/interface/act.interface';
import { ActService } from '../../../custom/api/service/act.service';
import { UserService } from '../../../custom/api/service/user.service';
import { PatientService } from '../../../custom/api/service/patient.service';

interface IDeleteSubmitModalProps {
	isOpen: boolean;
	setIsOpen(...args: unknown[]): unknown;
	title: string;
	patient: boolean;
	uuid?: string;
}

const DeleteSubmitModal: FC<IDeleteSubmitModalProps> = ({
	isOpen,
	setIsOpen,
	title,
	patient,
	uuid,
}) => {
	const queryClient = useQueryClient();

	const { mutate: deleteUser } = useMutation((uuid: string) => UserService.delete(uuid), {
		onSuccess: () => {
			queryClient.invalidateQueries('admins');
			queryClient.invalidateQueries('workers');
			queryClient.invalidateQueries('users');
			setIsOpen(false);
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const { mutate: deletePatient } = useMutation((uuid: string) => PatientService.delete(uuid), {
		onSuccess: () => {
			queryClient.invalidateQueries('patients');
			setIsOpen(false);
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (uuid && !patient) {
			deleteUser(uuid);
		}
		if (uuid && patient) {
			deletePatient(uuid);
		}

		setIsOpen(false);
	};

	return (
		<>
			<Modal isCentered isOpen={isOpen} setIsOpen={setIsOpen}>
				<ModalHeader setIsOpen={setIsOpen} className='p-4'>
					<span className='fs-5 fw-bold'>
						<Icon icon='Payment' className='me-2 fs-2' color='primary' />
						Подтверждение удаления
					</span>
				</ModalHeader>
				<form onSubmit={handleSubmit}>
					<ModalBody className='px-4'>
						<>
							<p className='fs-4 '>Вы уверены что хотите удалить {title} ?</p>
						</>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex flex-row col-12 gap-3 px-3'>
							<Button
								color='danger'
								className='col-6'
								onClick={() => {
									setIsOpen(false);
								}}>
								Нет
							</Button>
							<Button color={'success'} type='submit' className='col-6'>
								Да
							</Button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default DeleteSubmitModal;
