import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithEmail, IUserProps } from '../custom/data/customData/receptionistData';
import { UserService } from '../custom/api/service/user.service';
import { useQuery } from 'react-query';
import qs from 'qs';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	setFontSize: any;
	userData: Partial<IUserProps>;
	fontSize: string;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
} // Import necessary modules and types

// Define your AuthContext and related interfaces

// Define your AuthContextProvider component
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {

	const [user, setUser] = useState<string>(localStorage.getItem('facit_authEmail') || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const [fontSize, setFontSize] = useState(
		JSON.parse(localStorage.getItem('fontSize') || '{}')?.name || 'Нормальный',
	);

	// useEffect to store user email in localStorage
	useEffect(() => {
		localStorage.setItem('facit_authEmail', user);
	}, [user]);

	// useEffect to fetch user data when user changes
	useEffect(() => {
		if (user !== '') {
			setUserData(getUserDataWithEmail(user));
		} else {
			setUserData({});
		}
	}, [user]);

	// Function to update user state
	const updateUser = (email: string) => {
		setUser(email);
	};

	const value = useMemo(
		() => ({
			user,
			setUser: updateUser,
			userData,
			setFontSize,
			fontSize,
		}),
		[user, userData, fontSize],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Define PropTypes for AuthContextProvider component

// Export AuthContextProvider component
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
