import React, { FC, useEffect, useState } from 'react';
import { CardBody } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { medicalCardPages } from '../../../../../menu';
import Input from '../../../../../components/bootstrap/forms/Input';
import Button from '../../../../../components/bootstrap/Button';
import { SubHeaderLeft, SubHeaderRight } from '../../../../../layout/SubHeader/SubHeader';
import Icon from '../../../../../components/icon/Icon';
import useDarkMode from '../../../../../hooks/useDarkMode';
import PaginationButtons, {
	PER_COUNT,
	dataPagination,
} from '../../../../../components/PaginationButtons';
import UserImage3 from '../../../../../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../../../../../assets/img/wanna/wanna3.webp';
import NewPatientVisitModal from '../NewPatientVisitModal';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import { IPatient } from '@textnpayme/custom/api/interface/patient.interface';
import { PatientService } from '../../../../../custom/api/service/patient.service';
import Avatar from '../../../../../components/Avatar';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useDebounce } from '../../../../../hooks/useDebounce';
import Tooltips from '../../../../../components/bootstrap/Tooltips';

interface IChoosePatientProps {
	setIsPatientChoosen(...args: unknown[]): unknown;
	// patient: any;
	setIsOpen(...args: unknown[]): unknown;
	setIsNewPatientChoosen(...args: unknown[]): unknown;
	setChoosenPatientId(...args: unknown[]): unknown;
	patientCreated: boolean;
	setPatientCreated(...args: unknown[]): unknown;
	setPatientCreatedId(...args: unknown[]): unknown;
	setPatientId(...args: unknown[]): unknown;
	setNewPatientIsOpen(...args: unknown[]): unknown;
	setCreateVisit(...args: unknown[]): unknown;
	newPatientIsOpen: boolean;
	// currentPage: number;
	// perPage: number;
	// setPerPage(...args: unknown[]): unknown;
}
const ChoosePatient: FC<IChoosePatientProps> = ({
	setIsOpen,
	// patient,
	setCreateVisit,
	setNewPatientIsOpen,
	setIsPatientChoosen,
	setIsNewPatientChoosen,
	setChoosenPatientId,
	setPatientCreatedId,
	patientCreated,
	setPatientCreated,
	setPatientId,
	newPatientIsOpen,
	// currentPage,
	// perPage,
	// setPerPage
}) => {
	const { darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['3']);
	const [isValid, setIsValid] = useState(false);
	const [patientSearch, setPatientSearch] = useState('');

	const validatePhoneNumber = (phoneNumber: string): void => {
		const phoneUtil = PhoneNumberUtil.getInstance();
		const number = phoneNumber.replace(/\+/g, '');
		try {
			const parsedNumber = phoneUtil.parse(number);
			setIsValid(phoneUtil.isValidNumber(parsedNumber));
		} catch (e) {
			const allRegions: string[] = phoneUtil.getSupportedRegions();
			let isValidForAnyRegion: boolean = false;
			for (const region of allRegions) {
				try {
					const parsedNumber = phoneUtil.parse(number, region);
					if (phoneUtil.isValidNumber(parsedNumber)) {
						isValidForAnyRegion = true;
						break;
					}
				} catch (e) {
					continue;
				}
			}
			setIsValid(isValidForAnyRegion);
		}
	};
	const [patientParts, setPatientParts] = useState<any>('');
	useEffect(() => {
		if (patientSearch.includes('+')) {
			validatePhoneNumber(patientSearch);
		}
		const newParts = patientSearch.split(' ');
		setPatientParts(newParts);
	}, [patientSearch]);

	const DebiuncedPatinetSearch = useDebounce(patientSearch);

	const { isLoading, data: patients }: { data: IPatient[] | any; isLoading: boolean } = useQuery(
		['patients', currentPage, perPage, DebiuncedPatinetSearch],
		() =>
			PatientService.findAll(
				qs.stringify({
					queryMeta: {
						order: { createdAt: 'DESC' },
						paginate: true,
						limit: perPage,
						page: DebiuncedPatinetSearch?.length ? 1 : currentPage,
					},
					includeMeta: [
						{
							...(patientSearch &&
								/\d/.test(patientSearch) &&
								!isValid && {
									association: 'passport',
									where: {
										or: [
											{
												passportNumber: {
													iLike: `%${patientSearch}%`,
												},
											},
											{
												ssCardId: {
													iLike: `%${patientSearch}%`,
												},
											},
										],
									},
								}),
						},
					],
					filterMeta: {
						...(patientSearch &&
							/\d/.test(patientSearch) &&
							isValid && { phone: { iLike: `%${patientSearch}%` } }),
						...(patientSearch &&
							!/\d/.test(patientSearch) &&
							!isValid &&
							((patientParts.length === 1 && {
								name: { iLike: `%${patientParts[0]}%` },
							}) ||
								(patientParts.length === 2 && {
									and: [
										{ name: { iLike: `%${patientParts[0]}%` } },
										{ surname: { iLike: `%${patientParts[1]}%` } },
									],
								}) ||
								(patientParts.length === 3 && {
									and: [
										{ name: { iLike: `%${patientParts[0]}%` } },
										{ surname: { iLike: `%${patientParts[1]}%` } },
										{ middleName: { iLike: `%${patientParts[2]}%` } },
									],
								}))),
					},
				}),
			),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		},
	);

	const [query, setQuery] = useState({
		queryMeta: {
			paginate: true,
			limit: perPage,
			page: currentPage,
		},
		filterMeta: {},
	});

	const handleSelect = (uuid: any) => {
		setPatientId(uuid);
		setIsPatientChoosen(true);
	};

	return (
		<>
			<div className='d-flex flex-row mb-3 gap-3'>
				<SubHeaderLeft>
					<label
						className='border-0 bg-transparent cursor-pointer me-0'
						htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>
					<Input
						id='searchInput'
						type='search'
						value={patientSearch}
						onChange={(e: any) => {
							setPatientSearch(e.target.value);
						}}
						className='border-0 shadow-none bg-transparent'
						placeholder='Поиск пациента по Имени Фамилии Отчеству или по номеру ЭМК'
					/>
				</SubHeaderLeft>
				<SubHeaderRight>
					<Button
						icon='PersonAdd'
						color='primary'
						onClick={() => {
							setNewPatientIsOpen(true);
						}}>
						Новый пациент{' '}
					</Button>
				</SubHeaderRight>
			</div>
			<CardBody className='table-responsive p-0'>
				<table className='table table-modern table-hover'>
					<thead>
						<tr>
							<th>№ карты пациента </th>
							<th>Пациент</th>
							<th>Номер телефона</th>
							<th>Статус</th>
							<th>Действие</th>
						</tr>
					</thead>
					<tbody>
						{patients?.data?.map((i: IPatient, index: number) => (
							<tr key={index}>
								<td className='fw-bold col-2'>
									<Tooltips
										title='Открыть ЭМК пациента в новой вкладке'
										placement='top'
										flip={['top']}>
										<Button
											className='fs-5 fw-bold'
											tag='a'
											isLink
											target='_blank'
											to={`../${medicalCardPages?.customerID?.path}/${i.uuid}`}>
											{i.patientId}
										</Button>
									</Tooltips>
								</td>
								<td className='col-3'>
									<Tooltips
										title='Открыть ЭМК пациента в новой вкладке'
										placement='top'
										flip={['top']}>
										<NavLink
											target='_blank'
											style={{
												textDecoration: 'none',
												fontWeight: 'bold',
											}}
											to={`../${medicalCardPages?.customerID?.path}/${i.uuid}`}>
											<div
												className={classNames(
													'fw-bold',
													'd-flex',
													'flex-row',
													'align-items-center',
													{
														'text-dark': !darkModeStatus,
													},
												)}>
												<Avatar
													src={
														i?.image
															? `https://backend.amracode.am${i?.image}`
															: UserImage3
													}
													size={36}
													className='me-3'
												/>
												{i?.name} {i?.surname} {i?.middleName}
											</div>
										</NavLink>
									</Tooltips>
								</td>
								<td className='fw-bold col-2'>{i?.phone}</td>
								<td className='col-2'>
									<div className='text-nowrap fw-bold'>
										{i.status === 'active' ? (
											<>
												<Icon icon='Circle' className='me-2' color='info' />
												<span
													style={{
														color: '#0d6efd',
													}}>
													Активный
												</span>
											</>
										) : (
											<>
												<Icon
													icon='Circle'
													className='me-2'
													color='warning'
												/>
												<span
													style={{
														color: '#FFCF52',
													}}>
													Неактивный
												</span>
											</>
										)}
									</div>
								</td>
								<td className='col-1'>
									<div className='d-flex flex-row'>
										<Button
											color='info'
											isLight
											className='text-nowrap me-2'
											style={{
												fontWeight: 'bold',
											}}
											onClick={() => handleSelect(i.uuid)}>
											Выбрать{' '}
										</Button>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				data={patients || []}
				label='customers'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
				totalPage={Math.ceil(patients?.meta?.count / perPage) || 1}
			/>
		</>
	);
};

export default ChoosePatient;
